import { useEffect, useMemo, useState } from 'react';

import { Section, SectionBody } from '@playbooks/interface/sections';
import { searchParams } from 'api';
import { SearchList } from 'components/search/search-list';
import { useStore } from 'contexts';
import { useQuery } from 'hooks';

const SearchTrendingSection = ({ api, tableName, tableNames, onClick }) => {
	const [trending, setTrending] = useState([]);
	const [params, setParams] = useState({ ...searchParams, ...api });
	const [meta, setMeta] = useState({ page: 0, pageSize: 0, totalRecords: 0 });
	const store = useStore();

	// Computed
	const computedFilters = useMemo(() => {
		return tableName ? { modelName: tableName } : { modelName: tableNames.join(',') };
	}, [tableName, tableNames]);

	const computedHits = useMemo(() => {
		return trending.map(v => v.result);
	}, [JSON.stringify(trending)]);

	// Hooks
	useEffect(() => {
		fetchData();
	}, [tableName]);

	// Actions
	const [fetchData, loading] = useQuery(async () => {
		const response = await store.query({
			url: `/search-trending`,
			params: { ...params, ...computedFilters },
		});
		setTrending(response.data);
		setMeta(response.meta);
	});

	// Render
	return (
		<Section border='' borderRadius='' spacing=''>
			<SectionBody size='md' spacing=''>
				<SearchList
					type='preview'
					modelName='trending'
					icon='magnifying-glass'
					hits={computedHits}
					loading={loading}
					onClick={onClick}
					tailwind={{ card: { spacing: 'p-4' }, div: { spacing: 'p-4' } }}
				/>
			</SectionBody>
		</Section>
	);
};

export { SearchTrendingSection };
