import { useEffect, useMemo, useState } from 'react';

import { BorderBtn } from '@playbooks/interface/buttons';
import { Section, SectionBody, SectionFooter } from '@playbooks/interface/sections';
import { SearchList } from 'components/search/search-list';
import { useStorage } from 'contexts';
import { useQuery } from 'hooks';

const SearchRecentSection = ({ api, tableName, tableNames, onClick }) => {
	const [recent, setRecent] = useState([]);
	const [params, setParams] = useState(api);
	const [meta, setMeta] = useState({ page: 0, pageSize: 0, totalRecords: 0 });
	const storage = useStorage();

	// Computed
	const computedHits = useMemo(() => {
		return recent.slice(api.page, api?.pageSize || 10).map(v => v.result);
	}, [JSON.stringify(recent)]);

	// Hooks
	useEffect(() => {
		fetchData();
	}, [tableName, storage.storage.search]);

	// Actions
	const [fetchData, loading] = useQuery(async () => {
		const queries = storage.storage.search || [];
		const formattedQueries = queries
			.filter(v => (tableName ? tableName === v.modelName : true))
			.filter(v => (tableNames.length > 0 ? tableNames.includes(v.modelName) : true))
			.sort((a, b) => (a.date < b.date ? 1 : -1));
		setRecent(formattedQueries);
		setMeta({ page: params.page, pageSize: params?.pageSize, totalRecords: formattedQueries.length });
	});

	const onClear = async () => {
		storage.storeValue('search', []);
		setRecent([]);
		setMeta({ page: 0, pageSize: 0, totalRecords: 0 });
	};

	// Render
	return (
		<Section border='' borderRadius='' spacing=''>
			<SectionBody size='md' spacing=''>
				<SearchList
					type='preview'
					modelName='recent'
					icon='magnifying-glass'
					hits={computedHits}
					loading={loading}
					onClick={onClick}
					tailwind={{ card: { spacing: 'p-4' }, div: { spacing: 'p-4' } }}
				/>
			</SectionBody>
			<SectionFooter border=''>
				<BorderBtn size='md' onClick={onClear} className='w-full'>
					Clear
				</BorderBtn>
			</SectionFooter>
		</Section>
	);
};

export { SearchRecentSection };
